/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-filename-extension */
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

import 'react-day-picker/lib/style.css';

import '../public/favicon.ico';
import '../public/manifest.json';
import '../src/styles/globalStyles.css';

import { GoogleTMConfiguration } from 'src/scripts/gtm';
import { updateHistory } from 'utils/sessionStorage';
import {
    AppProvider,
    AttractionsProvider,
    SearchProvider,
    CartProvider,
    CheckoutProvider,
    DateProvider,
    ThemeProvider,
    WishlistProvider,
} from '../src/common/app/contexts';
import msIcon144x144 from '/images/favicon/ms-icon-144x144.png';
import { ShareIcons } from '../src/shared/ShareIcons';
// import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
// import { GW_CLIENT_KEY } from '../src/common/app/constants/keys';

const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    'name': 'TripShock',
    'url': 'https://www.tripshock.com/',
    'logo': 'https://www.tripshock.com/image/tripshock-logo.png',
    'contactPoint': {
        '@type': 'ContactPoint',
        'telephone': '+1-850-424-5125',
        'contactType': 'General Contact',
        'areaServed': 'US',
        'availableLanguage': 'English',
    },
    'sameAs': [
        'https://www.instagram.com/tripshock/',
        'https://www.facebook.com/tripshockofficial/',
        'https://www.tiktok.com/@tripshock_official',
        'https://www.youtube.com/@Tripshock',
        'https://www.linkedin.com/company/tripshock-/',
    ],
    'description':
        'TripShock is an online marketplace that allows activity and tour providers to sell their tickets and tours with the help of affiliates, resellers, and property managers.',
    'foundingDate': '2009',
    'founders': [
        {
            '@type': 'Person',
            'name': 'Greg Fisher',
        },
    ],
    'address': {
        '@type': 'PostalAddress',
        'streetAddress': '3999 Commons Dr W, Unit N',
        'addressLocality': 'Destin',
        'addressRegion': 'FL',
        'postalCode': '32541',
        'addressCountry': 'US',
    },
};

function MyApp({ Component, pageProps }) {
    const router = useRouter();
    const purePath = router.asPath.split('?')[0];

    // const gb = useMemo(() => {
    //     return new GrowthBook({
    //         apiHost: 'https://cdn.growthbook.io',
    //         clientKey: GW_CLIENT_KEY,
    //         enableDevMode: true,
    //         trackingCallback: (experiment, result) => {
    //             if (typeof window !== 'undefined' && window.dataLayer) {
    //                 window.dataLayer.push({
    //                     event: 'experiment_viewed',
    //                     experiment_id: experiment.key,
    //                     variation_id: result.variationId,
    //                 });
    //             }
    //         },
    //     });
    // }, []);

    // useEffect(() => {
    //     // Fetch feature payload from GrowthBook
    //     gb.init({
    //         // Optional, enable streaming updates
    //         streaming: true,
    //     });

    //     // Set targeting attributes for the user
    //     let uuid = cookies.load('gb-next-example-userId');
    //     if (!uuid) {
    //         uuid = Math.random().toString(36).substring(2);
    //         cookies.save('gb-next-example-userId', uuid);
    //     }

    //     gb.setAttributes({
    //         id: uuid,
    //     });
    // }, [gb]);

    useEffect(() => {
        updateHistory(router.asPath);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [purePath]);

    const { fullList, currentDestination, currentCategory, currentPartnerId, recommendedIds } =
        pageProps;

    return (
        <>
            <Head>
                <meta httpEquiv="x-ua-compatible" content="IE=edge" />
                <meta charSet="utf-8" />
                <meta
                    name="description"
                    key="description"
                    content="The USA's #1 booking website for activities, tours, and things to do. Browse reviews, deals, photos and more from over 1,000+ experiences. Low price guarantee on every booking."
                />
                <meta name="msapplication-TileImage" content={msIcon144x144.src} />
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,maximum-scale=1,shrink-to-fit=no"
                />
                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="application-name" content="TripShock" />
                <meta name="apple-mobile-web-app-title" content="TripShock" />
                <meta name="theme-color" content="#2b3b46" />
                <meta name="msapplication-navbutton-color" content="#2b3b46" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="theme-color" content="#ffffff" />
            </Head>

            {/* <GrowthBookProvider growthbook={gb}> */}
            <ThemeProvider>
                <DateProvider>
                    <AppProvider>
                        <SearchProvider
                            currentPartnerId={currentPartnerId}
                            currentDestinationId={currentDestination?.id}
                            currentCategoryId={currentCategory?.id}
                        >
                            <WishlistProvider>
                                <CartProvider>
                                    <CheckoutProvider>
                                        <AttractionsProvider
                                            fullList={fullList}
                                            currentDestination={currentDestination}
                                            currentCategory={currentCategory}
                                            recommendedIds={recommendedIds}
                                        >
                                            <Component {...pageProps} />
                                        </AttractionsProvider>
                                    </CheckoutProvider>
                                </CartProvider>
                            </WishlistProvider>
                        </SearchProvider>
                    </AppProvider>
                </DateProvider>
            </ThemeProvider>

            <Script strategy="afterInteractive" id="ServiceWorker">
                {`if ('serviceWorker' in navigator) {
                    window.addEventListener('load', function() {
                        navigator.serviceWorker
                            .register('/service-worker.js')
                            .then(reg => console.log('ServiceWorker registration successful with scope: ', reg.scope),
                                    err => console.log('ServiceWorker registration failed: ', err))
                            .catch(err => console.log(err));
                    });
                    } else {
                        console.log('service worker is not supported');
                    };`}
            </Script>
            <Script
                id="GTMinject"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: GoogleTMConfiguration,
                }}
            />
            <Script
                async
                id="AdSense"
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2763822498467686"
                crossOrigin="anonymous"
                strategy="afterInteractive"
                onError={(e) => {
                    console.error('AdSesnse init error:', e);
                }}
            />
            <ShareIcons />
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(structuredData),
                }}
            />
            {/* </GrowthBookProvider> */}
        </>
    );
}

export default MyApp;
