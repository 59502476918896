import { IDateRange } from '@/common/domain/Date.domain';
import { addDays, format, startOfToday, differenceInDays, parseISO, startOfDay } from 'date-fns';
import { SEARCH_FORMAT_DATE, SERVER_FORMAT_DATE } from '../constants/timeConstants';
import { TDate } from '../contexts/DateContext';
import { ParsedUrlQuery } from 'querystring';
import { addTwoWeeks } from './addWeeks';

export const getDateByTimezone = (date: Date) => {
    return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
};

export const dateQueryString = (date?: Date) => {
    const currentDate = typeof date === 'string' ? parseISO(date) : date || startOfToday();
    return format(currentDate, SEARCH_FORMAT_DATE);
};

export const getNormalisedDateByQuery = ({
    query,
    date,
}: {
    query: ParsedUrlQuery;
    date?: TDate;
}): TDate | undefined => {
    try {
        if (typeof window === 'undefined') return;
        const lastSessionDates = window.localStorage.getItem('dates');
        if (!query.from && !lastSessionDates) {
            return date;
        }
        const parseDate = JSON.parse(lastSessionDates || '{}');
        const from = getFormattedDateByQuery(query.from) || parseDate.from;
        const to = getFormattedDateByQuery(query.to) || parseDate.to;
        const currentDate = startOfToday();
        const choosenDate = getDateByTimezone(new Date(from || ''));

        const dayIsExpired = choosenDate < currentDate;

        if (dayIsExpired) return date;

        const newFrom = choosenDate;
        const maxDate = addTwoWeeks(newFrom);
        const reqTo = to ? startOfDay(getDateByTimezone(new Date(to))) : undefined;
        const newTo = reqTo && reqTo <= maxDate && reqTo > newFrom ? reqTo : addDays(newFrom, 6);

        return {
            from: format(newFrom, SERVER_FORMAT_DATE),
            to: format(newTo, SERVER_FORMAT_DATE),
        };
    } catch (error) {
        return date;
    }
};

export const getFormattedDateByQuery = (query?: string | string[]) => {
    try {
        if (query && typeof query === 'string') {
            const regexp = /^(1[0-2]|0?[1-9])\/(3[01]|[12][0-9]|0?[1-9])\/(?:[0-9]{2})?[0-9]{2}$/;
            const regexpServer =
                /^(?:[0-9]{2})?[0-9]{2}-(1[0-2]|0?[1-9])-(3[01]|[12][0-9]|0?[1-9])$/;
            if (regexp.test(query)) {
                const arrDate = query.split('/');
                return `${arrDate[2]}-${arrDate[0]}-${arrDate[1]}`;
            }

            if (regexpServer.test(query)) {
                return query;
            }
        }
        return undefined;
    } catch (error) {
        return undefined;
    }
};

export const getFormattedRangeDate = (date?: Date) => {
    const currentDate = typeof date === 'string' ? parseISO(date) : date || startOfToday();
    return format(currentDate, SERVER_FORMAT_DATE);
};

export const getRangeDateBySlug = (query: TDate) => {
    const dateStart = new Date(query.from);
    const dateEnd = new Date(query.to);

    return {
        dateStart: getDateByTimezone(dateStart),
        dateEnd: getDateByTimezone(dateEnd),
    };
};

export const getRangeDateByQuery = (from?: string | string[], to?: string | string[]) => {
    const fromDate = from && typeof from === 'string' ? new Date(from) : new Date();
    const toDate = to && typeof to === 'string' ? new Date(to) : addDays(fromDate, 6);

    return {
        from: format(getDateByTimezone(fromDate), SERVER_FORMAT_DATE),
        to: format(getDateByTimezone(toDate), SERVER_FORMAT_DATE),
    };
};

export const addQueryString = (queryString?: string) => (queryString ? `/?${queryString}` : '');

export const dateRangeQueryString = (startDate?: Date, endDate?: Date) => {
    const startDateString = dateQueryString(startDate);
    const endDateString = dateQueryString(endDate);
    return `?from=${startDateString}&to=${endDateString}`;
};

export const addSlashString = (value?: string) => {
    if (value && value.length) {
        return `/${value}`;
    }
    return '';
};

export const getAttractionsUrl = ({
    destinationSlug,
    stateCode,
    categorySlug,
    partner,
    urlDate,
}: {
    destinationSlug: string;
    stateCode: string;
    categorySlug?: string;
    partner?: string;
    urlDate?: IDateRange;
}) => {
    if (partner) {
        return `${partner}${dateRangeQueryString(
            urlDate?.dateStart,
            urlDate?.dateEnd ?? addDays(startOfToday(), 6)
        )}`
            .replace(/ /g, '')
            .toLowerCase();
    }

    return `/destination/${stateCode}/${destinationSlug}/things-to-do/${addSlashString(
        categorySlug
    )}${dateRangeQueryString(urlDate?.dateStart, urlDate?.dateEnd ?? addDays(startOfToday(), 6))}`
        .replace(/ /g, '')
        .toLowerCase();
};

export const getNewDateRange = (dateRange: IDateRange): IDateRange => {
    const today = startOfToday();

    if (
        differenceInDays(
            typeof dateRange.dateStart === 'string'
                ? parseISO(dateRange.dateStart)
                : dateRange.dateStart,
            today
        ) < 0
    ) {
        return {
            dateStart: today,
            dateEnd: addDays(today, 7),
        };
    }

    return {
        dateStart:
            typeof dateRange.dateStart === 'string'
                ? parseISO(dateRange.dateStart)
                : dateRange.dateStart,
        dateEnd:
            typeof dateRange.dateEnd === 'string' ? parseISO(dateRange.dateEnd) : dateRange.dateEnd,
    };
};
