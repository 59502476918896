import { SERVER_FORMAT_DATE } from '@/common/app/constants/timeConstants';
import { addDays, addWeeks, format, parseISO, startOfDay, subWeeks, startOfToday } from 'date-fns';
import { useRouter } from 'next/router';

import {
    createContext,
    memo,
    PropsWithChildren,
    useContext,
    useMemo,
    useState,
    useEffect,
    useCallback,
} from 'react';
import { getDateByTimezone, getFormattedDateByQuery } from '../utils/dateUtils';
import { addTwoWeeks } from '../utils/addWeeks';

type ContextProps = {
    date?: TDate;
    changeDate: (from?: string, to?: string) => void;
    nextWeek: () => void;
    prevWeek: () => void;
};

export type TDate = {
    from: string;
    to: string;
};

const DEFAULT_STORE = {
    date: {
        from: '',
        to: '',
    },
    changeDate: () => {
        return;
    },
    nextWeek: () => {
        return;
    },
    prevWeek: () => {
        return;
    },
};

const DateContext = createContext<ContextProps>(DEFAULT_STORE);

const DateContextProvider = ({ children }: PropsWithChildren<ContextProps>) => {
    // const [date, setDate] = useState<TDate>({
    //     from: format(new Date(), SERVER_FORMAT_DATE),
    //     to: format(addDays(new Date(), 6), SERVER_FORMAT_DATE),
    // });

    const [date, setDate] = useState<TDate>();

    const { asPath, replace, query, pathname } = useRouter();
    const queryFromDate = useMemo(() => getFormattedDateByQuery(query.from), [query.from]);
    const queryToDate = useMemo(() => getFormattedDateByQuery(query.to), [query.to]);

    const changeDate = useCallback(
        (from?: string, to?: string) => {
            const currentDate = startOfToday();
            const choosenDate = getDateByTimezone(new Date(from || ''));

            const dayIsExpired = choosenDate < currentDate;
            const newFrom = dayIsExpired ? currentDate : choosenDate;
            const maxDate = addTwoWeeks(newFrom);
            const reqTo = to ? startOfDay(getDateByTimezone(new Date(to))) : undefined;
            const dayIsLarge = !!reqTo && reqTo > maxDate;
            const newTo = reqTo && reqTo <= maxDate ? reqTo : addDays(newFrom, 6);

            const newDates = {
                from: format(newFrom, SERVER_FORMAT_DATE),
                to: format(newTo, SERVER_FORMAT_DATE),
            };

            setDate(newDates);

            window.localStorage.setItem('dates', JSON.stringify(newDates));

            if (dayIsExpired || dayIsLarge) {
                replace(asPath.split('?')[0], undefined, { shallow: true });
            }
        },
        [asPath, replace]
    );

    const nextWeek = useCallback(async () => {
        const dateFrom = date?.from || format(new Date(), SERVER_FORMAT_DATE);
        const newFrom = addWeeks(parseISO(dateFrom), 1);
        const newTo = addDays(newFrom, 6);

        setDate({
            from: format(newFrom, SERVER_FORMAT_DATE),
            to: format(newTo, SERVER_FORMAT_DATE),
        });

        window.sessionStorage.setItem('dateFrom', format(newFrom, SERVER_FORMAT_DATE));
    }, [date?.from]);

    const prevWeek = useCallback(async () => {
        const dateFrom = date?.from || format(new Date(), SERVER_FORMAT_DATE);
        const today = startOfDay(new Date());
        const prevFrom = subWeeks(parseISO(dateFrom), 1);

        const newFrom = prevFrom < today ? today : prevFrom;
        const newTo = addDays(newFrom, 6);

        setDate({
            from: format(newFrom, SERVER_FORMAT_DATE),
            to: format(newTo, SERVER_FORMAT_DATE),
        });
        window.sessionStorage.setItem('dateFrom', format(newFrom, SERVER_FORMAT_DATE));
    }, [date?.from]);

    useEffect(() => {
        /* eslint-disable sonarjs/no-collapsible-if */
        if (
            (queryFromDate && queryFromDate !== date?.from) ||
            (queryToDate && queryToDate !== date?.to)
        ) {
            return changeDate(queryFromDate, queryToDate);
        }

        const lastSessionDates = window.localStorage.getItem('dates');
        if (lastSessionDates) {
            try {
                const parseDates = JSON.parse(lastSessionDates);
                const currentDate = startOfToday();
                const choosenDate = getDateByTimezone(new Date(parseDates.from));
                const dayIsExpired = choosenDate < currentDate;

                if (
                    !dayIsExpired &&
                    (parseDates.from !== date?.from || parseDates.to !== date?.to)
                ) {
                    changeDate(parseDates);
                }
            } catch (error) {
                return;
            }
        }
    }, [queryFromDate, queryToDate, date?.from, date?.to, changeDate]);

    useEffect(() => {
        if (pathname === '/[ActivitySlug]/details/[ActivityId]') {
            const { from, to, ...otherPrms } = query;

            if (from || to) {
                replace({ query: { ...otherPrms } }, undefined, { shallow: true });
            }
        }
    }, [pathname, query, replace]);

    const contextProviderValue = useMemo(
        () => ({
            date,
            changeDate,
            nextWeek,
            prevWeek,
        }),

        [date, changeDate, nextWeek, prevWeek]
    );

    return <DateContext.Provider value={contextProviderValue}>{children}</DateContext.Provider>;
};

export const DateProvider = memo(DateContextProvider);

export const useDateContext = () => {
    const context = useContext(DateContext);

    if (!context) {
        throw new Error('useDateContext must be used within a DateProvider');
    }

    return context;
};
