import { AnalyticsData } from '@/shared/FeaturedActivities/ui/featuredActivitiesPreview/FeaturedActivitiesPreview.types';
import { IItem } from './interfaces';
import { IActivityWithAvailability } from '@/common/domain/Merge.domain';
import { IGoogle } from '@/common/domain/Google.domain';
import dateless from '../../../../scripts/cache/dateless.json';
import { NextRouter } from 'next/router';
import {
    getCS,
    getLS,
    getSessionStorage,
    setLS,
    setSessionStorage,
} from '@/common/service/storage';
import { IActivity } from '@/entities/Activity/domain/Activity.domain';
import { AdditionalOptions, CartData, IApiCartItem } from '@/entities/Cart/domain/Cart.domain';
import { IOrderDetail } from '@/entities/Checkout/domain/Checkout.domain';

export const createListNameFromPath = (): string => {
    const pathname = typeof window !== 'undefined' ? window.location.pathname : '';
    const base = 'Featured Placement';

    if (pathname.includes('/things-to-do/')) {
        const idCategoryPage = !!pathname.split('/things-to-do/');
        return `${base} - ${idCategoryPage ? 'Category' : 'Destination'}`;
    }
    if (pathname.includes('/details/')) {
        return `${base} - Activity`;
    }
    if (pathname.includes('/blog')) {
        return `${base} - Blog`;
    }
    if (pathname.includes('/partners')) {
        return `${base} - Partners`;
    }
    if (pathname.includes('/destination')) {
        return `${base} - Visitors Guide`;
    }
    if (pathname.includes('/itinerary')) {
        return `${base} - Itinerary`;
    }

    if (pathname.includes('/tour')) {
        return `${base} - Landing Page`;
    }

    return base;
};

export const convertRecommendedDataToAnalyticsData = ({
    id,
    name,
    analytics_data,
    google,
    price,
    price_strike_out,
    position,
    item_list_name,
    item_list_id,
    isFeatured,
}: {
    id: string;
    name: string;
    analytics_data?: AnalyticsData;
    google?: IGoogle;
    price: number;
    price_strike_out?: number | null;
    position: number;
    item_list_name: string;
    item_list_id: string;
    isFeatured: boolean;
}): IItem => {
    const additionalData = getTripDataById(id);
    const coupon = getCS('ts_promocode');
    return {
        item_id: id,
        item_name: name,
        index: position + 1,
        item_brand: analytics_data?.brand || additionalData.item_brand,
        item_brand_id: additionalData.item_brand_id,
        item_category: analytics_data?.category.split(' - ')[0] || additionalData.item_category,
        item_category_id: additionalData.item_category_id,
        item_category2: analytics_data?.category.split(' - ')[1] || additionalData.item_category2,
        item_category2_id: additionalData.item_category2_id,
        ...(isFeatured && { item_category3: createListNameFromPath() }),
        location_id: google?.place_id || additionalData.location_id,
        price: price,
        quantity: 1,
        item_list_name,
        item_list_id,
        ...(coupon ? { coupon: `${coupon}` } : {}),
        ...(price_strike_out
            ? { discount: Math.round((price_strike_out - price) * 100) / 100 }
            : {}),
    };
};

export const convertActivityDataToAnalyticsData = (
    item: IActivityWithAvailability,
    position: number,
    item_list_name: string,
    item_list_id: string
): IItem => {
    const coupon = getCS('ts_promocode');
    return {
        item_id: item.id,
        item_name: item.name,
        index: position + 1,
        item_brand: item.title,
        item_brand_id: item.partner_id,
        item_category: item.city?.name,
        item_category_id: item.city?.id,
        item_category2: item.category?.name,
        item_category2_id: item.category?.id,
        location_id: item.google.place_id,
        price: item.price,
        quantity: 1,
        item_list_name,
        item_list_id,
        ...(coupon ? { coupon: `${coupon}` } : {}),
        ...(item.price_strike_out
            ? { discount: Math.round((item.price_strike_out - item.price) * 100) / 100 }
            : {}),
    };
};

// need to remove after update api

type TDateLessTrip = {
    id: string;
    partner_id: string;
    name: string;
    title: string;
    google: { place_id: string };
    city: { id: string; name: string };
    category: { id: string; name: string };
};
export const getTripDataById = (
    id: string
): {
    item_brand: string;
    item_brand_id: string;
    item_category: string;
    item_category_id: string;
    item_category2: string;
    item_category2_id: string;
    location_id: string;
} => {
    const datelessItems = Object.values(
        JSON.parse(JSON.stringify(dateless)) as { [key: string]: { items: TDateLessTrip[] } }
    ).reduce((acc: TDateLessTrip[], curr) => {
        return [...acc, ...curr.items];
    }, []);
    const item = (datelessItems as TDateLessTrip[]).find((i) => i.id === id);
    return {
        item_brand: item?.title || '',
        item_brand_id: item?.partner_id || '',
        item_category: item?.city.name || '',
        item_category_id: item?.city.id || '',
        item_category2: item?.category.name || '',
        item_category2_id: item?.category.id || '',
        location_id: item?.google.place_id || '',
    };
};

// for details pages //

export const encodeQueryString = (data: string) => {
    return data.replace(/&/g, '%26').replace(/\//g, '%2F');
};

export const decodeQueryString = (data: string) => {
    return data.replace(/%26/g, '&').replace(/%2F/g, '/');
};

export const createParamsForDetails = (
    position: number,
    item_list_name: string,
    item_list_id: string,
    price: number,
    price_strike_out?: number | null,
    promo?: {
        item_category3: string;
        promotion_name: string;
    }
) => {
    const data = {
        position,
        list_name: item_list_name,
        list_id: item_list_id,
        p: price,
        ...(price_strike_out ? { pso: price_strike_out } : {}),
        ...(promo ? { placement: promo.item_category3, promo_name: promo.promotion_name } : {}),
    };

    return Object.entries(data)
        .map(([key, value]) => encodeQueryString(`${key}=${value}`))
        .join('&');
};

interface TDetailsSessionData {
    index: number;
    item_list_name: string;
    item_list_id: string;
    promotion_name?: string;
    item_category3?: string;
}

export const wraitDetasilsData = ({
    index,
    item_list_name,
    item_list_id,
    item_category3,
    promotion_name,
}: {
    index: number;
    item_list_name: string;
    item_list_id: string;
    item_category3?: string;
    promotion_name?: string;
}): void => {
    const ga4Data = {
        index,
        item_list_name,
        item_list_id,
        promotion_name,
        item_category3,
    };

    setSessionStorage('ga4Data', ga4Data);
};

export const readDetailsData = (): TDetailsSessionData | undefined => {
    const data: TDetailsSessionData | undefined = getSessionStorage('ga4Data');
    return data;
};

export const getDetailsData = (
    router: NextRouter
):
    | (TDetailsSessionData & {
          price: number;
          price_strike_out: number;
      })
    | undefined => {
    const {
        query: { position, list_name, list_id, placement, promo_name, p, pso },
    } = router;

    if (position || list_name || list_id || placement || promo_name || p || pso) {
        const item_list_name = (list_name && decodeQueryString(list_name as string)) || '';
        const item_list_id = (list_id && decodeQueryString(list_id as string)) || '';
        const promotion_name = promo_name && decodeQueryString(promo_name as string);
        const item_category3 = placement && decodeQueryString(placement as string);
        const price = (p && +decodeQueryString(p as string)) || 0;
        const price_strike_out = (pso && +decodeQueryString(pso as string)) || 0;

        const ga4Data = {
            index: +(position as string) || 0,
            item_list_name,
            item_list_id,
            promotion_name,
            item_category3,
        };

        setSessionStorage('ga4Data', ga4Data);

        return { ...ga4Data, price, price_strike_out };
    }
    return undefined;
};

interface TDetailsLocalData extends TDetailsSessionData {
    item_id: string;
    item_brand_id: string;
    location_id: string;
    coupon?: string;
    discount?: number;
}
interface IDetailsLocalDataObj {
    [key: string]: TDetailsLocalData;
}

const getPrice = ({
    subtotal,
    additional_options,
}: {
    subtotal: number;
    additional_options: AdditionalOptions[];
}): number => {
    const tripflexValue = +(
        additional_options?.find((option) => +option.id === 1)?.value?.price || 0
    );

    return Math.round((subtotal - tripflexValue) * 100) / 100;
};

export const getAnalyticsDataForTrackCart = (activity: IActivity, cartData: CartData) => {
    const currentCart: IDetailsLocalDataObj = getLS('ga4Data', {});
    const currentInfo: TDetailsSessionData | undefined = getSessionStorage('ga4Data');

    const newCartData: {
        [key: string]: IItem;
    } = {};

    let value = 0;

    const allCartData: IDetailsLocalDataObj = cartData.cart_items.reduce((acc, curr) => {
        if (currentCart[curr.id]) {
            return {
                ...acc,
                [curr.id]: currentCart[curr.id],
            };
        }

        if (activity.id === curr.product_id) {
            const coupon = cartData.discount?.promo?.code;
            const discount = cartData.discount?.cart_items?.[curr.id]?.discount;
            const newCartItem: TDetailsLocalData = {
                item_id: curr.product_id,
                index: +(currentInfo?.index || 1),
                item_brand_id: activity.partner_id,
                location_id: activity.google.place_id,
                item_list_name: currentInfo?.item_list_name || '',
                item_list_id: currentInfo?.item_list_id || '',
                ...(currentInfo?.item_category3
                    ? { item_category3: currentInfo.item_category3 }
                    : {}),
                ...(coupon ? { coupon } : {}),
                ...(discount ? { discount } : {}),
            };
            newCartData[curr.id] = {
                ...newCartItem,
                item_id: activity.id,
                item_name: activity.name,
                item_brand: activity.title,
                item_brand_id: activity.partner_id,
                item_category: activity.city_name,
                item_category_id: activity.city_id,
                item_category2: activity.activity_categories[0].name,
                item_category2_id: activity.activity_categories[0].id,
                location_id: activity.google.place_id,
                quantity: 1,
                price: getPrice({
                    subtotal: curr.subtotal,
                    additional_options: curr.additional_options,
                }),
                item_variant: curr.booked_format,
                ...(coupon ? { coupon } : {}),
                ...(discount ? { discount } : {}),
            };
            value += curr.total;

            console.log('data 02');

            return {
                ...acc,
                [curr.id]: newCartItem,
            };
        }
        return acc;
    }, {});

    const data = {
        value: Math.round(value * 100) / 100,
        currency: 'USD',
        items: Object.values(newCartData),
    };

    setLS('ga4Data', allCartData);
    return data;
};

export const getAnalyticsDataForTrackViewCart = (data: CartData) => {
    const currentCart: IDetailsLocalDataObj = getLS('ga4Data', {});

    const analyticsData: {
        [key: string]: IItem;
    } = {};

    const allCartData: IDetailsLocalDataObj = data.cart_items.reduce((acc, curr) => {
        const coupon = data.discount?.promo?.code;
        const discount = data.discount?.cart_items?.[curr.id]?.discount;
        analyticsData[curr.id] = {
            item_name: curr.product_name,
            item_brand: curr.partner_title,
            item_category: curr.product_city_info.name,
            item_category_id: curr.product_city_info.id,
            item_category2: curr.product_category_info.name,
            item_category2_id: curr.product_category_info.id,
            quantity: 1,
            price: getPrice({
                subtotal: curr.subtotal,
                additional_options: curr.additional_options,
            }),
            item_variant: curr.booked_format,
            ...(currentCart[curr.id]
                ? { ...currentCart[curr.id] }
                : {
                      item_id: curr.product_id,
                      index: 1,
                      location_id: '',
                      item_brand_id: '',
                  }),
            ...(coupon ? { coupon } : {}),
            ...(discount ? { discount } : {}),
        };

        if (currentCart[curr.id]) {
            return {
                ...acc,
                [curr.id]: currentCart[curr.id],
            };
        }

        return {
            ...acc,
            [curr.id]: {
                item_id: curr.product_id,
                index: 1,
                item_list_name: '',
                item_list_id: '',
            },
        };
    }, {});

    setLS('ga4Data', allCartData);

    return {
        value: Math.round(data.total * 100) / 100,
        currency: 'USD',
        items: Object.values(analyticsData),
    };
};

export const getAnalyticsDataForTrackRemoveCart = (item: IApiCartItem) => {
    const currentCart: IDetailsLocalDataObj = getLS('ga4Data', {});

    const allCartData: IDetailsLocalDataObj = Object.keys(currentCart).reduce((acc, curr) => {
        if (curr === item.id) {
            return acc;
        }
        return {
            ...acc,
            [curr]: currentCart[curr],
        };
    }, {});

    const data = {
        value: Math.round(item.total * 100) / 100,
        currency: 'USD',
        items: [
            {
                item_name: item.product_name,
                item_brand: item.partner_title,
                item_category: item.product_city_info.name,
                item_category_id: item.product_city_info.id,
                item_category2: item.product_category_info.name,
                item_category2_id: item.product_category_info.id,
                quantity: 1,
                price: getPrice({
                    subtotal: item.subtotal,
                    additional_options: item.additional_options,
                }),
                item_variant: item.booked_format,
                ...currentCart[item.id],
            },
        ],
    };

    setLS('ga4Data', allCartData);
    return data;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const getAnalyticsDataForPurchase = (data: IOrderDetail) => {
    const currentCart: IDetailsLocalDataObj = getLS('ga4Data', {});

    let tax = 0;
    const coupon = data.discount?.promo?.code;
    const discount_purchase = data.discount?.total;

    const items = Object.values(data.cart_items).reduce((acc: IItem[], curr) => {
        tax += curr.surcharges;
        const discount = data.discount?.cart_items?.[curr.id]?.discount || 0;

        const subtotalWithoutTripflex =
            Object.values(curr.tickets).reduce((acc, curr) => acc + +curr.price * +curr.booked, 0) +
            Object.values(curr.additional_options).reduce((acc, curr) => {
                if (+curr.id !== 1) {
                    return acc + +(curr.value.price || 0);
                }
                return acc;
            }, 0) -
            discount;

        return [
            ...acc,
            {
                item_id: curr.activity.id,
                item_name: curr.trip_name,
                index: +(currentCart[curr.id].index || 1),
                item_brand: curr.partner_name,
                item_brand_id: curr.partner_id,
                item_category: curr.product_city_info.name,
                item_category_id: curr.product_city_info.id,
                item_category2: curr.product_category_info.name,
                item_category2_id: curr.product_category_info.id,
                item_variant: curr.booked_format,
                item_list_id: currentCart[curr.id]?.item_list_id || '',
                item_list_name: currentCart[curr.id]?.item_list_name || '',
                location_id: curr.activity.google.place_id,
                price: Math.round(subtotalWithoutTripflex * 100) / 100,
                quantity: 1,
                ...(currentCart[curr.id]?.item_category3
                    ? {
                          item_category3: currentCart[curr.id].item_category3,
                      }
                    : {}),
                ...(coupon ? { coupon } : {}),
                ...(discount ? { discount } : {}),
            },
        ];
    }, []);

    setLS('ga4Data', {});

    return {
        currency: 'USD',
        commission: Math.round(data.comission * 100) / 100,
        ...(coupon ? { coupon } : {}),
        ...(discount_purchase ? { discount_purchase } : {}),
        items: items,
        tax: Math.round(tax * 100) / 100,
        transaction_id: data.id,
        value: Math.round(data.gross_total * 100) / 100,
    };
};

export const float = (num: number | string): number => +Number(num).toFixed(2);
