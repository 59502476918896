import { IFullList } from '@/common/service/api/Activity/Activity.domain';
import { fetchActivityDateless } from '@/common/service/api/Activity/Activity';
import { Today } from '../domain/Attractions.domain';
import { IAvailability } from '@/common/service/api/Availability/Availability.domain';
import { fetchAvailabilityShortByIds } from '@/common/service/api/Availability/Availability';

export const getActivityDateless = async (
    destination_id: string,
    category_id?: string | number
): Promise<IFullList> => {
    return await fetchActivityDateless(destination_id, category_id);
};

export const getAvailabilityShortByIds = async (
    trip_ids: string,
    dates: Today,
    promocode?: string
): Promise<IAvailability[]> => {
    if (!trip_ids) {
        return [];
    }

    return await fetchAvailabilityShortByIds({
        trip_ids,
        ...dates,
        promocode,
    });
};
