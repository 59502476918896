import instance from '@/common/service/api/Axios';
import {
    TPartnersInfo,
    TPartnerListDetails,
    TPartnerByIdValues,
    TPartnerById,
    TActivityPartnerById,
    TPartnerListProp,
    IPartnersInfoDestination,
} from './Partners.domain';
import { ApiUrls } from '@/common/domain/Endpoints.domain';
import { IFullList } from '../Activity/Activity.domain';
import { getTripDataById } from '@/shared/Analytics/GA4/helpers';

export const getPartnersHome = async (): Promise<IPartnersInfoDestination[]> => {
    const { data } = await instance.get<TPartnersInfo>(ApiUrls.partners.home, {
        params: { limit: 100 },
    });
    return data.destinations;
};

export const getPartner = async ({ destination_id, offset, limit }: TPartnerListProp) => {
    const { data } = await instance.get<TPartnerListDetails>(ApiUrls.partners.root, {
        params: { destination_id, offset, limit },
    });
    return data && typeof data === 'object' && Array.isArray(data.items) ? data : { items: [] };
};

export const getPartnerById = (params: string) => {
    return instance
        .get<TPartnerById>(ApiUrls.partners.root, { params: { id: params } })
        .then(({ data }) => {
            if (data.length > 1) {
                throw new Error(`[warn] More than one partners at list /partners/${params}`);
            }
            if (Array.isArray(data) && data[0]) {
                return data[0];
            }

            return null;
        });
};

const fetchActivityPartnerById = async (
    params: TPartnerByIdValues
): Promise<TActivityPartnerById> => {
    try {
        const { data } = await instance.get<TActivityPartnerById>(ApiUrls.activity.root, {
            params,
        });

        if (!data || !data.items) {
            throw new Error(`[warn] No activity at list /activity/${params}`);
        }
        return data;
    } catch (error) {
        return {
            meta: {
                title: '',
                description: '',
            },
            total: 0,
            activity_ids: '',
            items: [],
        };
    }
};

export const getActivityPartnerById = async (params: TPartnerByIdValues): Promise<IFullList> => {
    try {
        const data = await fetchActivityPartnerById({ ...params, limit: 10 });

        let activity_ids = data.activity_ids,
            items = data.items.map((i) => {
                const additionalData = getTripDataById(i.id);
                return {
                    ...i,
                    city: {
                        name: additionalData.item_category,
                        id: additionalData.item_category_id,
                    },
                    category: {
                        name: additionalData.item_category2,
                        id: additionalData.item_category2_id,
                    },
                    google: {
                        place_id: additionalData.location_id,
                    },
                };
            }),
            offset = data.items.length,
            count = 0;
        const total = data.total;

        while (total > items.length && count < 10) {
            const more = await fetchActivityPartnerById({ ...params, offset, limit: 10 });
            activity_ids += more.activity_ids ? `,${more.activity_ids}` : '';
            items = [
                ...items,
                ...more.items.map((i) => {
                    const additionalData = getTripDataById(i.id);
                    return {
                        ...i,
                        city: {
                            name: additionalData.item_category,
                            id: additionalData.item_category_id,
                        },
                        category: {
                            name: additionalData.item_category2,
                            id: additionalData.item_category2_id,
                        },
                        google: {
                            place_id: additionalData.location_id,
                        },
                    };
                }),
            ];
            offset += more.items.length;
            count += 1;
        }

        return {
            ...data,
            activity_ids: activity_ids || '',
            items,
        };
    } catch (error) {
        return {
            total: 0,
            activity_ids: '',
            items: [],
            meta: {
                title: '',
                description: '',
            },
        };
    }
};
