import { useRouter } from 'next/router';
import Script from 'next/script';
import { ReactElement, useEffect } from 'react';
import { useMedia } from '@/common/service/hooks/useMedia';

const SHARE_THIS_URL =
    'https://platform-api.sharethis.com/js/sharethis.js#property=5cc218cc36d0970012b884fd&product=sticky-share-buttons';

export const ShareIcons = (): ReactElement => {
    const { pathname } = useRouter();
    const isMobile = useMedia('(max-width: 1024px)');

    const showBtns = pathname.includes('/blog');
    const shareButtonsContainer =
        typeof document !== 'undefined' && document.getElementById('st-2');

    useEffect(() => {
        if (isMobile && !showBtns && getComputedStyle(document.body).paddingBottom !== '0px') {
            document.body.style.paddingBottom = '0px';
        }
    }, [showBtns, isMobile]);

    if (!showBtns) {
        if (shareButtonsContainer) {
            shareButtonsContainer.style.display = 'none';
        }
        return <></>;
    }

    if (shareButtonsContainer) {
        shareButtonsContainer.style.display = 'flex';
    }

    return <Script src={SHARE_THIS_URL} id="sharethis" />;
};
