import {
    createContext,
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { TDate, useDateContext } from '@/common/app/contexts/DateContext';
import { IDateRange } from '@/common/domain/Date.domain';
import { IActivityItem, IFullList } from '@/common/service/api/Activity/Activity.domain';
import { ICategory } from '@/common/service/api/Categories/Categories.domain';
import { IPartnersItem } from '@/common/service/api/Partners/Partners.domain';
import {
    IDestination,
    IDestinationCategory,
    IDestinationList,
} from '@/entities/Destination/domain/Destination.domain';
import { useRouter } from 'next/router';

import { getAvailabilityShortByIds } from '@/entities/Attractions/service/ApiAttractionsPage';
import {
    checktAvailability,
    CreateParams,
    getAvailableData,
} from '@/entities/Attractions/service/Creators';
import {
    getSortFunction,
    OPTIONS,
    SORT_OPTIONS,
    sortByBestMatch,
} from '@/entities/Attractions/service/SortFunctions';

import { useCartContext } from '.';
import { loadCookiePromocode, saveCookiePromocode } from '@/common/service/storage';
import { filterByAttribution, parseAttr } from '../constants/attributions';
import { getPromocodeName } from '@/common/service/api/Promocodes/Promocode';
import { getNormalisedDateByQuery } from '../utils/dateUtils';
import { IAvailability } from '@/common/service/api/Availability/Availability.domain';

type AttractionsProviderProps = PropsWithChildren<{
    fullList?: IFullList;
    recommendedIds?: string[];
    currentDestination?: IDestinationList;
    currentCategory?: IDestinationCategory;
}>;

export interface IAttractionsSearch {
    destination: IDestination;
    category?: ICategory;
    partner?: IPartnersItem;
    dateRange: IDateRange;
}

export interface IDestinationPartners {
    loading: boolean;
    items: IPartnersItem[];
    destination_id?: string;
}

interface IAttractionState {
    defaultIds?: string;
    recommendedIds?: string;
    checkedList: string[];
    availability: Record<string, IAvailability>;
}

interface IAttractionLoadingState {
    initLoading: boolean;
    changeLoading: boolean;
    initChangeLoading: boolean;
    paginationLoading: boolean;
}

interface IAttractionSettingsState {
    categories: string[];
    attributes: number[];
    sorting: (typeof OPTIONS)[number];
    initDate?: TDate;
}

type ContextProps = {
    state: IAttractionState;
    finalList: IActivityItem[];
    availability: Record<string, IAvailability>;
    setState: Dispatch<SetStateAction<IAttractionState>>;
    loading: IAttractionLoadingState;
    setLoading: Dispatch<SetStateAction<IAttractionLoadingState>>;
    settings: IAttractionSettingsState;
    setSettings: Dispatch<SetStateAction<IAttractionSettingsState>>;
    normalisedDate?: TDate;
    totalLength: number;
    allProcessedLenght: number;
    isComplete: boolean;
    onScrollPage: (data: { count?: number }) => Promise<void>;
    availableAttributesIds: number[];
    normalisedList: IActivityItem[];
};

const AttractionsContext = createContext<ContextProps | null>(null);

export const AttractionsProvider = ({ children, ...props }: AttractionsProviderProps) => {
    const { date } = useDateContext();
    const { runToast } = useCartContext();
    const { query, isReady } = useRouter();

    const normalisedDate = useMemo(() => {
        // add check localStorage
        return getNormalisedDateByQuery({ query, date });
    }, [query, date]);

    const [state, setState] = useState<IAttractionState>({
        checkedList: [],
        availability: {},
    });

    const [loading, setLoading] = useState<IAttractionLoadingState>({
        initLoading: true,
        paginationLoading: false,
        changeLoading: false,
        initChangeLoading: false,
    });

    const [settings, setSettings] = useState<{
        categories: string[];
        attributes: number[];
        sorting: (typeof OPTIONS)[number];
        initDate?: TDate;
    }>({
        sorting: OPTIONS[0],
        attributes: [],
        categories: [],
        initDate: normalisedDate,
    });

    const [page, setPage] = useState<number>(1);

    const normalisedList = useMemo(() => {
        if (!props.fullList) return [];

        const filteredByCategories = settings.categories.length
            ? props.fullList.items.filter((i) =>
                  i.activity_categories.some((id) => settings.categories.includes(id))
              )
            : props.fullList.items;
        const filteredByAttributes = filterByAttribution({
            trips: filteredByCategories,
            attributes: settings.attributes,
        });

        const isDefaultSort = settings.sorting === SORT_OPTIONS.bestMatch;
        const sortFunc = getSortFunction(settings.sorting);

        return isDefaultSort
            ? sortByBestMatch(filteredByAttributes, props.fullList.activity_ids || '')
            : [...filteredByAttributes].sort(sortFunc);
    }, [props.fullList, settings.attributes, settings.categories, settings.sorting]);

    const { totalLength, allProcessedLenght, isComplete } = useMemo(() => {
        return {
            totalLength: normalisedList.length,
            allProcessedLenght: state.checkedList.length,
            isComplete: normalisedList.every((i) => !!state.checkedList.includes(i.id)),
        };
    }, [normalisedList, state.checkedList]);

    const finalList = useMemo(() => {
        if (!normalisedDate) {
            return normalisedList.slice(0, (page - 1) * 10 + 12);
        }

        return isComplete
            ? [
                  ...normalisedList.filter(
                      ({ id }) =>
                          state.availability[id] && checktAvailability(state.availability[id])
                  ),
                  ...normalisedList.filter(
                      ({ id }) =>
                          state.availability[id] && !checktAvailability(state.availability[id])
                  ),
              ].slice(0, (page - 1) * 10 + 12)
            : normalisedList
                  .filter(
                      ({ id }) =>
                          state.availability[id] && checktAvailability(state.availability[id])
                  )
                  .slice(0, (page - 1) * 10 + 12);
    }, [normalisedDate, isComplete, normalisedList, page, state.availability]);

    const availableAttributesIds = useMemo(() => {
        const ids: number[] = [];
        normalisedList.forEach((trip) => {
            if (trip.attributes) {
                const attrs = parseAttr(trip.attributes);
                ids.push(...attrs.filter((i) => !ids.includes(i)));
            }
        });
        return ids;
    }, [normalisedList]);

    const getMoreAvailability = useCallback(
        async ({ count, list }: { count: number; list: string[] }): Promise<void> => {
            const newList = list.slice(0, 10);
            const { activity_ids, dates, promocode } = CreateParams({
                list: newList,
                date: normalisedDate,
            });

            const availability = await getAvailabilityShortByIds(activity_ids, dates, promocode);

            const { availabilityObj, availableCount } = getAvailableData(availability);

            setState((prev) => ({
                ...prev,
                availability: {
                    ...prev.availability,
                    ...availabilityObj,
                },
                checkedList: [...prev.checkedList, ...newList],
            }));

            if (count > availableCount && list.length > 10) {
                return getMoreAvailability({
                    list: list.slice(10),
                    count: count - availableCount,
                });
            }

            setLoading((prev) => ({
                ...prev,
                paginationLoading: false,
                initLoading: false,
                changeLoading: false,
            }));
        },
        [normalisedDate]
    );

    const onScrollPage = useCallback(async () => {
        setLoading((prev) => ({
            ...prev,
            paginationLoading: true,
        }));

        setPage((prev) => prev + 1);

        if (!normalisedDate) {
            setLoading((prev) => ({
                ...prev,
                paginationLoading: false,
            }));
        }

        const { checkedList } = state;

        const lastList = normalisedList.filter((i) => !checkedList.includes(i.id));
        const newList = lastList.slice(0, 10).map(({ id }) => id);

        const { promocode, activity_ids, dates } = CreateParams({
            list: newList,
            date: normalisedDate,
        });

        const availability = await getAvailabilityShortByIds(activity_ids, dates, promocode);

        const { availabilityObj, availableCount } = getAvailableData(availability);

        setState((prev) => ({
            ...prev,
            availability: {
                ...prev.availability,
                ...availabilityObj,
            },
            checkedList: [...prev.checkedList, ...newList],
        }));

        if (normalisedDate && availableCount < 10 && lastList.length > 10) {
            return getMoreAvailability({
                list: lastList.slice(10).map(({ id }) => id),
                count: 10 - availableCount,
            });
        }

        setLoading((prev) => ({
            ...prev,
            paginationLoading: false,
        }));
    }, [getMoreAvailability, normalisedDate, normalisedList, state]);

    const initAvailability = useCallback(
        async ({
            promo,
            date,
            list,
            recommendedListIds,
        }: {
            promo?: string;
            date?: TDate;
            list: IActivityItem[];
            recommendedListIds: string[];
        }) => {
            // ========= without dates turn off loading ========= //
            if (!date) {
                setLoading((prev) => ({
                    ...prev,
                    initLoading: false,
                }));
            }

            // ====== ids recommended trips & first 16 activity ===== //
            const idsForAvailability = !date
                ? list.slice(0, 16).map(({ id }) => id)
                : [
                      ...recommendedListIds,
                      ...list
                          .slice(0, 16)
                          .map(({ id }) => id)
                          .filter((i) => !recommendedListIds.includes(i)),
                  ];

            // ===== return if no trips ===== //
            if (!idsForAvailability.length) {
                return setLoading((prev) => ({
                    ...prev,
                    initLoading: false,
                }));
            }

            // ===== create prms for availability short ==== //
            const {
                promocode: currentPromo,
                activity_ids,
                dates,
            } = CreateParams({
                list: idsForAvailability,
                date,
                promocode: promo,
            });

            // ===== fetch availability & promo ===== //
            const [availability, promocode] = await Promise.all([
                getAvailabilityShortByIds(activity_ids, dates, currentPromo),
                currentPromo ? await getPromocodeName(currentPromo) : undefined,
            ]);

            // ==== save & show promo if exists ==== //
            if (promocode?.name) {
                saveCookiePromocode(promocode.name);
                runToast?.('promocode', {
                    promoName: promocode.name || '',
                });
            }

            // ===== normalise availability data ==== //
            const { availabilityObj, availableCount } = getAvailableData(availability);

            // ==== write fetched availability & trip ids ==== //
            setState((prev) => ({
                ...prev,
                availability: availabilityObj,
                checkedList: idsForAvailability,
            }));

            // ===== if we have dates & not enough available trips
            // getting more availability for next trips ==== //
            if (date && availableCount < 12 && list.length > availability.length) {
                return getMoreAvailability({
                    count: 12 - availableCount,
                    list: list
                        .slice(16)
                        .map(({ id }) => id)
                        .filter((i) => !idsForAvailability.includes(i)),
                });
            }

            setLoading((prev) => ({
                ...prev,
                initLoading: false,
            }));
        },
        [runToast, getMoreAvailability]
    );

    const changeParams = useCallback(
        async ({ date, list }: { date?: TDate; list: IActivityItem[] }) => {
            setPage(1);
            if (!date) {
                setLoading((prev) => ({
                    ...prev,
                    changeLoading: false,
                }));
            }

            const { checkedList } = state;
            const lastList = list.filter(({ id }) => !checkedList.includes(id));

            if (!lastList.length) {
                return setLoading((prev) => ({
                    ...prev,
                    changeLoading: false,
                }));
            }

            const newList = lastList.slice(0, 16).map(({ id }) => id);
            const { activity_ids, dates, promocode } = CreateParams({ list: newList, date });

            const availability = await getAvailabilityShortByIds(activity_ids, dates, promocode);

            const { availabilityObj, availableCount } = getAvailableData(availability);

            setState((prev) => ({
                ...prev,
                availability: {
                    ...prev.availability,
                    ...availabilityObj,
                },
                checkedList: [...prev.checkedList, ...newList],
            }));

            if (date && lastList.length > 16 && availableCount < 12) {
                return getMoreAvailability({
                    count: 12 - availableCount,
                    list: lastList.slice(16).map(({ id }) => id),
                });
            }

            setLoading((prev) => ({
                ...prev,
                changeLoading: false,
            }));
        },
        [getMoreAvailability, state]
    );

    useEffect(() => {
        if (!isReady || (!props.fullList && !props.recommendedIds?.length)) return;

        const isNewPage =
            state.defaultIds !== props.fullList?.activity_ids ||
            state.recommendedIds !== props.recommendedIds?.join(',');
        const isNewDate =
            normalisedDate?.from !== settings.initDate?.from ||
            normalisedDate?.to !== settings.initDate?.to;

        const oldPromo = loadCookiePromocode();
        const promo =
            typeof query.promo !== 'string' ? oldPromo : query.promo.toString().toLowerCase();

        if (isNewPage || isNewDate) {
            setState({
                defaultIds: props.fullList?.activity_ids,
                recommendedIds: props.recommendedIds?.join(','),
                availability: {},
                checkedList: [],
            });
            setPage(1);
            setLoading({
                initLoading: true,
                paginationLoading: false,
                changeLoading: false,
                initChangeLoading: false,
            });
            setSettings((prev) =>
                isNewPage
                    ? {
                          sorting: OPTIONS[0],
                          attributes: [],
                          categories: [],
                          initDate: normalisedDate,
                      }
                    : {
                          ...prev,
                          initDate: normalisedDate,
                      }
            );

            initAvailability({
                promo,
                date: normalisedDate,
                list: normalisedList,
                recommendedListIds: props.recommendedIds || [],
            });
        }

        if (loading.initChangeLoading) {
            setLoading((prev) => ({
                ...prev,
                initChangeLoading: false,
            }));
            changeParams({ date: settings.initDate, list: normalisedList });
        }
    }, [
        normalisedDate,
        initAvailability,
        changeParams,
        isReady,
        normalisedList,
        props.fullList,
        query.promo,
        settings.initDate,
        state.defaultIds,
        loading.initChangeLoading,
        loading.initLoading,
        props.recommendedIds,
        state.recommendedIds,
    ]);

    return (
        <AttractionsContext.Provider
            value={{
                state,
                setState,
                loading,
                setLoading,
                settings,
                setSettings,
                totalLength,
                finalList,
                availability: state.availability,
                allProcessedLenght,
                isComplete,
                onScrollPage,
                availableAttributesIds,
                normalisedList,
                normalisedDate,
            }}
        >
            {children}
        </AttractionsContext.Provider>
    );
};

export const useAttractionsContext = () => {
    const context = useContext(AttractionsContext);

    if (!context) {
        throw new Error('Context must be used within a Context Provider');
    }

    return context;
};
