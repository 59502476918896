import { isDevMode, isRuntime, isWindow } from '@/common/app/constants/envs';
import axios, { AxiosError } from 'axios';
import { Agent } from 'https';
import { captureException } from '@sentry/nextjs';
import URL from '@/common/app/utils/urlsUtils';
import { ITERABLE_KEY, CRS_KEY } from '@/common/app/constants/keys';

const axiosTimeout = 180_000;
const _agentOptions = { keepAlive: true };

const DefaultAxiosConfig = {
    baseURL: URL.api,
    timeout: axiosTimeout,
    httpsAgent: new Agent(_agentOptions),
    httpAgent: new Agent(_agentOptions),
    transitional: {
        forcedJSONParsing: true,
    },
};

const instance = axios.create(DefaultAxiosConfig);

export const imageInstance = axios.create({
    ...DefaultAxiosConfig,
    baseURL: URL.image,
});

export const apiImageInstance = axios.create({
    ...DefaultAxiosConfig,
    baseURL: URL.apiImage,
});

export const contentInstance = axios.create({
    ...DefaultAxiosConfig,
    baseURL: URL.content,
});

export const talkToUsInstance = axios.create({
    ...DefaultAxiosConfig,
    baseURL: URL.talkToUs,
});

export const affiliatesInstance = axios.create({
    ...DefaultAxiosConfig,
    baseURL: URL.affiliates,
});

export const iterableInstance = axios.create({
    ...DefaultAxiosConfig,
    baseURL: URL.iterable,
    headers: {
        'Content-Type': 'application/json',
        'Api-Key': ITERABLE_KEY,
    },
});

export const crsInstance = axios.create({
    ...DefaultAxiosConfig,
    baseURL: URL.crs,
    headers: {
        'X-Api-Key': CRS_KEY,
    },
});

export const redirectsInstance = axios.create({
    ...DefaultAxiosConfig,
    baseURL: URL.redirects,
});

const errorHandler = (error: AxiosError): Promise<AxiosError> => {
    if (
        error.request instanceof Object &&
        error.request.method &&
        error.request.path &&
        !error.request.path.includes('/public/faq')
    ) {
        console.log(`Failed ${error?.request?.method} ${error?.request?.path}`);

        captureException(error, {
            extra: {
                requestData: {
                    url: error.response?.config.url,
                    method: error.response?.config.method,
                    headers: error.response?.config.headers,
                    instance: error.response?.config.baseURL,
                    request_data: error.response?.config.data,
                },
                responseData: {
                    data: error.response?.data,
                    status: error.response?.status,
                },
            },
        });
    }

    return Promise.reject(error);
};

const createApiLink = (query?: { [key: string]: string }) => {
    if (!query) {
        return '';
    }

    return `?${Object.entries(query)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')}`;
};

if (isDevMode) {
    instance.interceptors.request.use((request) => {
        console.info('[Axios Request]', request.url, request.params);
        console.info(
            '[Axios Request]',
            `${request.baseURL}${request.url}${createApiLink(request.params)}`
        );
        return request;
    });

    contentInstance.interceptors.request.use((request) => {
        console.info('[Axios Content Request]', request.baseURL, request.url, request.params);
        console.info(
            '[Axios Content Request]',
            `${request.baseURL}${request.url}${createApiLink(request.params)}`
        );
        return request;
    });

    redirectsInstance.interceptors.request.use((request) => {
        console.info('[Axios Redirect Request]', request.baseURL, request.url, request.params);
        console.info(
            '[Axios Redirect Request]',
            `${request.baseURL}${request.url}${createApiLink(request.params)}`
        );
        return request;
    });
}

const interceptorID = instance.interceptors.request.use((request) => {
    if (!isRuntime() && !isWindow()) {
        request.baseURL = process.env.DIRECT_API_URL || URL.api;
    } else {
        request.baseURL = URL.api;
        instance.interceptors.request.eject(interceptorID);
    }
    return request;
});

const interceptorContentID = contentInstance.interceptors.request.use((request) => {
    if (!isRuntime() && !isWindow()) {
        request.baseURL = process.env.DIRECT_API_URL_CONTENT || URL.content;
    } else {
        request.baseURL = URL.content;
        contentInstance.interceptors.request.eject(interceptorContentID);
    }
    return request;
});

instance.interceptors.response.use(
    (response) => response,
    (error) => errorHandler(error)
);
iterableInstance.interceptors.response.use(
    (response) => response,
    (error) => errorHandler(error)
);
imageInstance.interceptors.response.use(
    (response) => response,
    (error) => errorHandler(error)
);
apiImageInstance.interceptors.response.use(
    (response) => response,
    (error) => errorHandler(error)
);
talkToUsInstance.interceptors.response.use(
    (response) => response,
    (error) => errorHandler(error)
);
affiliatesInstance.interceptors.response.use(
    (response) => response,
    (error) => errorHandler(error)
);
contentInstance.interceptors.response.use(
    (response) => response,
    (error) => errorHandler(error)
);

export default instance;
